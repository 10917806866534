<template>
  <div :style="backgroundDiv" style="font-family: 'Montserrat'; sans-serif;">
    <br />

    <v-container style="width: 100%; position: relative">
      <v-row align="left" justify="space-around">
        <v-btn href="/dash" style="width: 40%; margin-top: 5px; margin-bottom: 5px" class="link"
          ><b>Dashboard</b> </v-btn
        >
        <v-btn
          href="#"
          style="width: 40%; margin-top: 5px; margin-bottom: 5px"
          color="primary"
          class="link"
          ><b>Buscador</b></v-btn
        >
      </v-row>
    </v-container>
    <v-container fluid>
      <v-checkbox :input-value="!getPage" @click="setPage(!getPage)">
        <template v-slot:label>
          <div style="color: black; margin-top:7px;">Seleccionar como pantalla de inicio</div>
        </template>
      </v-checkbox>
    </v-container>
    <br />
    <v-card
      max-width="320"
      style="
        height: 540px;
        margin-left: 2%;
        margin-right: 2%;
        border-radius: 10px 10px 10px 10px;
        background-color: rgb(235, 235, 235);
      "
    >
      <!--Card title-->
      <v-container style="padding: 0">
        <h1
          style="
            background-color: rgb(62, 62, 62);
            border-radius: 10px 10px 0 0;
            text-align: center;
            color: white;
            font-size: 18px;
            padding: 8px;
          "
        >
          RESERVAR
        </h1>
      </v-container>
      <!--Buscador autocomplete-->
      <v-container fluid style="width: 100%; height: 130px">
        <v-row align="left">
          <v-card-subtitle
            style="text-align: left; color: rgb(62, 62, 62); font-size: 18px"
          >
            <b>Destino / Hotel</b>
          </v-card-subtitle>
          <v-col cols="12">
            <v-autocomplete
              v-model="values"
              :items="items"
              dense
              small-chips
              prepend-inner-icon="mdi-city"
              solo
              style="margin-top:-20px;"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <!--Check-in/ Check-out-->
      <v-container id="primero" style="width: 100%; height: auto; padding: 0; margin-top:-25px;" >
        <v-container
          fluid
          style="  width:100%;  position absolute; padding:0; text-align:left;"
        >
          <v-container
            id="entrada"
            style="
              width: 50%;
              background-color: ;
              position: relative;
              float: left;
            "
          >
            <b
              style="
                text-align: center;
                color: rgb(62, 62, 62);
                font-size: 18px;
                margin-left: 5%;
              "
              >Llegada</b
            >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label=""
                  prepend-icon=""
                  prepend-inner-icon="mdi-calendar"
                  solo
                  dense
                  v-bind="attrs"
                  v-on="on"
                  style="font-size:14px;"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable locale="Es">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-container>
          <v-container
            id="salida"
            style="
              width: 50%;
              background-color: ;
              position: relative;
              float: right;
            "
          >
            <b
              style="
                text-align: center;
                color: rgb(62, 62, 62);
                font-size: 18px;
                margin-left: 5%;
              "
              >Salida</b
            >
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="date1"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date1"
                  label=""
                  prepend-icon=""
                  prepend-inner-icon="mdi-calendar"
                  solo
                  dense
                  v-bind="attrs"
                  v-on="on"
                  style="font-size:14px;"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date1" no-title scrollable locale="Es">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu1 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu1.save(date1)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-container>
        </v-container>
      </v-container>
      <!--Nights / Rooms-->
      <v-container style="width: 100%; height: 100px; padding: 0">
        <v-container
          fluid
          style="  width:100%;  position absolute; padding:0; text-align:left;"
        >
          <v-container
            id="entrada"
            style="
              width: 50%;
              padding: 0;
              background-color: ;
              position: relative;
              float: left;
            "
          >
            <b
              style="
                text-align: center;
                color: rgb(62, 62, 62);
                font-size: 18px;
                margin-left: 13%;
              "
              >Noches</b
            >
            <v-form style="padding: 0" disabled>
              <v-container>
                <v-layout>
                  <v-flex>
                    <v-text-field
                      type="number"
                      step="any"
                      min="0"
                      ref="input"
                      solo
                      dense
                      :rules="[numberRule]"
                      v-model.number="number"
                      style="margin-top:-10px;"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
          <v-container
            id="salida"
            style="
              width: 50%;
              padding: 0;
              background-color: ;
              position: relative;
              float: right;
            "
          >
            <b
              style="
                text-align: center;
                color: rgb(62, 62, 62);
                font-size: 18px;
                margin-left: 13%;
              "
              >Habitaciones</b
            >
            <v-form style="padding: 0; margin-button: 10px">
              <v-container>
                <v-layout>
                  <v-flex>
                    <v-text-field
                      type="number"
                      step="any"
                      min="0"
                      ref="input"
                      solo
                      dense
                      :rules="[numberRule]"
                      v-model.number="rooms"
                      height="1px"
                      style="margin-top:-10px;"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-container>
      </v-container>
      <!--Adults / Minors-->
      <v-container style="width: 100%; height: 100px; padding: 0">
        <v-container
          fluid
          style="  width:100%;  position absolute; padding:0; text-align:left;"
        >
          <v-container
            id="entrada"
            style="
              width: 50%;
              padding: 0;
              background-color: ;
              position: relative;
              float: left;
            "
          >
            <b
              style="
                text-align: center;
                color: rgb(62, 62, 62);
                font-size: 18px;
                margin-left: 13%;
              "
              >Adultos</b
            >
            <v-form style="padding: 0">
              <v-container>
                <v-layout>
                  <v-flex>
                    <v-text-field
                      type="number"
                      step="any"
                      min="0"
                      ref="input"
                      solo
                      dense
                      :rules="[numberRule]"
                      v-model.number="adults"
                      height="1px"
                      style="margin-top:-10px;"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
          <v-container
            id="salida"
            style="
              width: 50%;
              padding: 0;
              background-color: ;
              position: relative;
              float: right;
            "
          >
            <b
              style="
                text-align: center;
                color: rgb(62, 62, 62);
                font-size: 18px;
                margin-left: 13%;
              "
              >Menores</b
            >
            <v-form style="padding: 0; margin-button: 10px">
              <v-container>
                <v-layout>
                  <v-flex>
                    <v-text-field
                      type="number"
                      step="any"
                      min="0"
                      ref="input"
                      solo
                      dense
                      :rules="[numberRule]"
                      v-model.number="minors"
                      height="1px"
                      style="margin-top:-10px;"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-container>
      </v-container>
      <!--Buscar reservacion-->
      <v-container style="width: 100%; height: 100px; padding: 0">
        <v-container
          fluid
          style="  width:100%;  position absolute; padding:0; text-align:center; background-color:black;"
        >
          <v-container
            id="entrada"
            style="
              width: 100%;
              position: relative;
              margin-top: -3%;
              float: left;
            "
          >
            <v-btn
              depressed
              @click="nochestotales"
              color="primary"
              style="width: 100%"
              href="\resultados"
              class="link"
            ><b>
              BUSCAR</b>
            </v-btn>
          </v-container>
        </v-container>
      </v-container>
    </v-card>
  </div>
</template>
<script src="http://momentjs.com/downloads/moment.min.js"></script>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "Cotizador",
  components: { DatePicker },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu1: false,
    items: [
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Federated States of Micronesia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Marshall Islands",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Palau",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virgin Island",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    values: [
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Federated States of Micronesia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Marshall Islands",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Palau",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virgin Island",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    time1: null,
    time2: null,
    number: null,
    rooms: null,
    adults: null,
    minors: null,
    /* de esta otra forma tambien se podria poner una imagen de fondo*/
    backgroundDiv: {
      height: "100%",
      backgroundImage: "url(" + require("../assets/dashboard.jpg") + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
    },
  }),
  methods: {
    ...mapActions(['setPage']),
    disabledBeforeTodayAndAfterAWeek(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return (
        date < today ||
        date > new Date(today.getTime() + 365 * 24 * 3600 * 1000)
      );
    },
    disabledBeforeTodayAndAfterAWeekAndCheckIn(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return (
        date < today ||
        date > new Date(today.getTime() + 365 * 24 * 3600 * 1000)
      );
    },
    nochestotales(time1, time2) {
      var fecha1 = moment(this.time1);
      var fecha2 = moment(this.time2);

      console.log(fecha2.diff(fecha1, "days"), " dias de diferencia");
    },

    numberRule: (val) => {
      if (val < 0) return "Please enter a positive number";
      return true;
    },
    date() {
      console.log(this.time1);

      console.log(this.time2);
    },
  },
  computed:{
    ...mapGetters(['getPage'])
  }
};
</script>
<style >
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400&display=swap");
</style>
<style>
</style>

